<template>
    <div>
        <div class="SecondTitleRegistration" style="display: flex; justify-content: center">
            <span>{{ $t("registration.counter") }} : {{ this.storeName }}</span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form>
                <van-field required disabled v-model="info.firstName" />

                <van-field required type="tel" v-model="info.mobile" disabled />

                <van-field
                    v-model="info.email"
                    :placeholder="this.$t('registration.formValueMobileEmailth')"
                    :error-message="errMessageEmail"
                />
                <div class="birth-style">
                    <span style="white-space: nowrap">{{ this.$t("registration.birthDay") }}:</span>
                    <van-field>
                        <template #input>
                            <BirthView :birth="info.birth" country="KR" />
                        </template>
                    </van-field>
                </div>

                <div class="gender-style">
                    <span style="white-space: nowrap">{{ this.$t("registration.gender") }}:</span>
                    <van-dropdown-menu class="gender-drop">
                        <van-dropdown-item
                            :title="genderTitle"
                            v-model="info.gender"
                            :options="genderOptions"
                        />
                    </van-dropdown-menu>
                </div>

                <!-- <div class="address-style">
                    <van-field
                        readonly
                        input-align="right"
                        is-link
                        label="Address"
                        placeholder="Search Address"
                        @click="daumPostcode()"
                    />
                    <van-popup
                        ref="zip"
                        v-model="show"
                        round
                        position="bottom"
                        :style="{ height: '85%' }"
                    >
                        <div id="wrap" style="width:100%;height:100%;min-height:100%;"></div>
                    </van-popup>

                    <div class="address-show" v-show="info.zipcode != ''">
                        <van-field
                            disabled
                            v-model="info.zipcode"
                            :placeholder="this.$t('registration.formValueZipcode')"
                        />
                        <van-field
                            disabled
                            type="textarea"
                            :autosize="true"
                            rows="1"
                            v-model="info.address1"
                            :placeholder="this.$t('registration.formValueAddress1')"
                        />
                        <van-field
                            disabled
                            type="textarea"
                            :autosize="true"
                            rows="1"
                            v-model="info.address2"
                            :placeholder="this.$t('registration.formValueAddress2')"
                        />
                        <van-field
                            type="textarea"
                            :autosize="true"
                            rows="1"
                            v-model="info.address3"
                            :placeholder="this.$t('registration.formValueAddress3')"
                        />
                    </div>
                </div> -->

                <div class="second-tips">
                    <div>
                        <input
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.bothPerAndProYN"
                            @change="onBothPerAndProYNChange(), validatorPersonalInfo()"
                        />
                    </div>
                    <div class="agreeStatement">
                        <span>{{ $t("registration.bothPerAndProYN") }}</span>
                    </div>
                </div>
                <div class="second-tips">
                    <div style="margin-left: 30px">
                        <input
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.personalInfoAgreeYN"
                            @change="validatorPersonalInfo()"
                        />
                    </div>
                    <div
                        class="agreeStatement"
                        v-html="
              $t('registration.formValuePInfoAgree').format(conditionsLink)
            "
                    ></div>
                </div>
                <div style="color: #ff0000; margin-left: 30px">{{ this.errMessagePersonalInfo }}</div>

                <div class="second-tips">
                    <div style="margin-left: 30px">
                        <input
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.promotionAgreeYN"
                            @change="validatorPersonalInfo(), validatorPromotionAgreeYN()"
                        />
                    </div>
                    <div
                        class="agreeStatement"
                        v-html="
              $t('registration.formValueMarketAgree').format(promotionAgreeLink)
            "
                    ></div>
                </div>
                <div
                    style="color: #ff0000; margin-left: 30px"
                >{{ this.errMessageMarketingAgreement }}</div>

                <div class="second-tips">
                    <div>
                        <input
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.followAgreeYN"
                            @change="followAgreeYNOnChange(), validatorPersonalInfo()"
                        />
                    </div>
                    <div class="agreeStatement">
                        <span>{{ $t("registration.followAgreeYN") }}</span>
                    </div>
                </div>

                <div class="desc-tips" style="margin-top: 15px">
                    <div>
                        <span class="desc-tips-agreeStatement">
                            {{
                            $t("registration.followAgreeYN1")
                            }}
                        </span>
                    </div>
                </div>
                <div class="desc-tips">
                    <div
                        class="desc-tips-agreeStatement"
                        v-html="
                $t('registration.followAgreeYN2').format(
                privacyPolicyLink,
                this.brandConfig.privacyDisplay
              )
            "
                    ></div>
                </div>
                <!-- <div class="desc-tips">
                    <div>
                        <span class="desc-tips-agreeStatement">
                            {{
                            $t("registration.followAgreeYN3")
                            }}
                        </span>
                    </div>
                </div> -->

                <div style="margin-top: 20px">
                    <van-button
                        :disabled="!submitDisabled"
                        size="large"
                        round
                        class="button-submit"
                        @click="submit()"
                        :loading="loading"
                    >{{ $t("registration.submit") }}</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"/>
<script>
import {
    Button,
    Cell,
    CellGroup,
    DropdownItem,
    DropdownMenu,
    Field,
    Switch,
    Checkbox,
    CheckboxGroup,
    Form,
    Radio,
    RadioGroup,
    DatetimePicker,
    Popup,
    Dialog,
} from "vant";

import { odsAddOrUpdate } from "@/api/eformApi";
import mixins from "./../mixin/Registration.js";
import { Toast } from "vant";
export default {
    name: "Registration",
    mixins: [mixins],
    components: {
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
        BirthView: () => import("./../components/BirthView"),
    },
    mounted() {
        if (
            localStorage.getItem("enterNumberInfo") &&
            localStorage.getItem("firstName")
        ) {
            this.info.mobile = localStorage.getItem("enterNumberInfo");
            this.info.firstName = localStorage.getItem("firstName");
        } else {
            this.$router.push({
                name: "NotFound",
            });
        }
    },
    data() {
        return {
            info: {
                firstName: "",
                birth: {
                    day: "",
                    month: "",
                    year: "",
                },
                gender: "3",
                mobile: "",
                email: "",
                address1: "",
                address2: "",
                address3: "",
                zipcode: "",
                bothPerAndProYN: false,
                personalInfoAgreeYN: false,
                promotionAgreeYN: false,
                smsAgreeYN: false,
                dmAgreeYN: false,
                emailAgreeYN: false,
                callAgreeYN: false,
                followAgreeYN: false,
            },
            show: false,
            daumObj: null,
            loading: false,
            errMessageEmail: "",
            errMessageZipCode: "",
            errMessagePersonalInfo: "",
            errMessageMarketingAgreement: "",
            genderOptions: [
                { text: this.$t("registration.male"), value: "1" },
                { text: this.$t("registration.female"), value: "2" },
            ],
        };
    },
    computed: {
        promotionAgreeLink() {
            if (this.brandConfig.marketing) {
                return `<span><a href="${
                    this.brandConfig.marketing
                }">${this.$t("common.marketing")}</a></span>`;
            } else {
                return `<span>${this.$t("common.marketing")}</span>`;
            }
        },
        privacyPolicyLink() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a href="${
                    this.brandConfig.privacyPolicy
                }">${this.$t("common.privacyPolicy")}</a></span>`;
            } else {
                return `<span>${this.$t("common.privacyPolicy")}</span>`;
            }
        },
        conditionsLink() {
            if (this.brandConfig.conditions) {
                return `<span><a href="${
                    this.brandConfig.conditions
                }">${this.$t("common.conditions")}</a></span>`;
            } else {
                return `<span>${this.$t("common.conditions")}</span>`;
            }
        },
        currentLocale() {
            return this.$root.$i18n.locale;
        },
        personalErrFlag() {
            return (
                this.info.firstName &&
                this.info.mobile &&
                this.info.email &&
                this.validatorEmail()
            );
        },
        genderTitle() {
            switch (this.info.gender) {
                case "1":
                    return this.$t("registration.male");
                case "2":
                    return this.$t("registration.female");
                case "3":
                    return this.$t("registration.select");
                default:
                    return this.$t("registration.select");
            }
        },
        submitDisabled() {
            if (!this.info.followAgreeYN) {
                return this.info.personalInfoAgreeYN;
            } else {
                return (
                    this.info.personalInfoAgreeYN && this.info.promotionAgreeYN
                );
            }
        },
    },
    watch: {
        currentLocale: {
            handler() {
                if (this.errMessageEmail != "") {
                    this.validatorEmail();
                }
                if (this.errMessageZipCode != "") {
                    this.validatorZipCode();
                }
                if (this.errMessagePersonalInfo != "") {
                    this.validatorPersonalInfo();
                }
                if (this.errMessageMarketingAgreement != "") {
                    this.validatorPromotionAgreeYN();
                }
                this.genderOptions = [
                    { text: this.$t("registration.male"), value: "1" },
                    { text: this.$t("registration.female"), value: "2" },
                ];
            },
        },
        personalErrFlag: {
            handler() {
                if (this.personalErrFlag && !this.info.personalInfoAgreeYN) {
                    this.errMessagePersonalInfo = this.$t(
                        "registration.rulerPersonalInfo1"
                    );
                }
            },
        },
        "info.email": {
            handler() {
                this.validatorEmail();
            },
        },
        "info.personalInfoAgreeYN": {
            handler() {
                this.info.bothPerAndProYN =
                    this.info.personalInfoAgreeYN && this.info.promotionAgreeYN;
            },
        },
        "info.promotionAgreeYN": {
            handler() {
                this.info.bothPerAndProYN =
                    this.info.personalInfoAgreeYN && this.info.promotionAgreeYN;
            },
        },
    },
    methods: {
        daumPostcode() {
            this.show = true;
            this.$nextTick(() => {
                var element_wrap = document.getElementById("wrap");
                let that = this;
                new daum.Postcode({
                    oncomplete: function (data) {
                        console.log(data);
                        that.info.address1 = data.roadAddress;
                        that.info.address2 = `(${data.bname},${data.buildingName})`;
                        that.info.zipcode = data.zonecode;
                        that.show = false;
                    },
                    width: "100%",
                    height: "100%",
                    animation: true,
                    hideMapBtn: true,
                    hideEngBtn: true,
                    theme: {
                        bgColor: "#FFFFFF",
                    },
                }).embed(element_wrap);
            });
        },
        followAgreeYNOnChange() {
            this.info.promotionAgreeYN = this.info.followAgreeYN;
            this.validatorPromotionAgreeYN();
        },
        onBothPerAndProYNChange() {
            this.info.personalInfoAgreeYN = this.info.promotionAgreeYN =
                this.info.bothPerAndProYN;
        },
        validatorPromotionAgreeYN() {
            this.errMessageMarketingAgreement = "";
            if (this.info.followAgreeYN && !this.info.promotionAgreeYN) {
                this.errMessageMarketingAgreement = this.$t(
                    "registration.rulerMarketingAgreement"
                );
                return false;
            }
            return true;
        },
        validatorPersonalInfo() {
            this.errMessagePersonalInfo = "";
            if (!this.info.personalInfoAgreeYN) {
                this.errMessagePersonalInfo = this.$t(
                    "registration.rulerPersonalInfo1"
                );
                return false;
            }
            return true;
        },
        validatorEmail() {
            this.errMessageEmail = "";
            if (this.info.email == "" || this.info.email.trim() == "") {
                return true;
            }
            const reg =
                /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;
            if (!reg.test(this.info.email)) {
                this.errMessageEmail = this.$t("registration.rulerEmail");
                return false;
            }
            return true;
        },
        validatorZipCode() {
            this.errMessageZipCode = "";
            const reg = /^[0-9]{5}$/;
            if (this.info?.zipcode && !reg.test(this.info?.zipcode)) {
                this.errMessageZipCode = this.$t("registration.rulerZipCode");
                return false;
            }
            return true;
        },
        submit() {
            this.loading = true;
            if (!this.validatorEmail() | !this.validatorPersonalInfo()) {
                this.loading = false;
                return;
            }

            this.info.smsAgreeYN =
                this.info.dmAgreeYN =
                this.info.emailAgreeYN =
                this.info.callAgreeYN =
                    this.info.followAgreeYN;
            odsAddOrUpdate({
                country: this.$route.params.countryPath,
                storeNo: this.$route.query.storeId,
                brandName: this.brandConfig.display,
                sessionKey: localStorage.getItem("sessionKey"),
                ...this.info,
            })
                .then((res) => {
                    if (res.success) {
                        if (res.data.result_code == "000") {
                            this.$router.push({
                                name: "RegistSuccess",
                            });
                            this.loading = false;
                        } else if (res.data.result_code == "204") {
                            Toast.fail({
                                type: "text",
                                className: "noticeWidth",
                                message: this.$t("common.sessionExpired"),
                                onClose: () =>
                                    this.$router.push(
                                        "/" +
                                            this.$route.params.countryPath +
                                            "/" +
                                            this.$route.params.brand +
                                            "/EnterNumber/" +
                                            this.$root.$i18n.locale +
                                            "?storeId=" +
                                            this.$route.query.storeId
                                    ),
                            });
                        } else {
                            Toast.fail(this.$t("common.toastFail"));
                            this.loading = false;
                        }
                    } else {
                        Toast.fail(this.$t("common.toastFail"));
                        this.loading = false;
                    }
                })
                .catch(() => {
                    Toast.fail(this.$t("common.toastFail"));
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

input.agreeCheckBox {
    transform: scale(1.5);
    margin-top: 33%;
}

.agreeStatement {
    margin-left: 10px;
}

.desc-tips-agreeStatement {
    font-size: 15px !important;
}

.second-tips {
    display: inline-flex;
    font-size: 14px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 15px;
    line-height: 20px;
    width: 100%;
}

.desc-tips {
    display: inline-flex;
    font-family: Helvetica-Regular;
    font-weight: 400;
    line-height: 20px;
    width: 90%;
    padding-left: 30px;
}

.second-no-checkbox-tips {
    margin-left: -10px;
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 10px;
    line-height: 20px;
    width: 100%;
}

.second-follow-tips {
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 5px;
    line-height: 20px;
    width: 100%;
}

.third-tips {
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 5px;
    line-height: 30px;
    width: 100%;
    margin-left: 30px;
}

.button-submit {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
}

/*下拉框样式*/
>>> .van-dropdown-menu {
    width: 100%;
}

>>> .van-dropdown-menu__title {
    font-size: 14px;
}

>>> .van-dropdown-menu__item {
    justify-content: left;
}

>>> .van-dropdown-menu__bar {
    height: unset;
    box-shadow: unset;
}

>>> .van-dialog {
    border-radius: 5px;
}

>>> .van-field__label {
    color: #262626;
}

>>> .van-field__control:disabled {
    -webkit-text-fill-color: #777777;
}

>>> .van-divider {
    margin: 0px;
    padding: 0% 35%;
}
</style>

<style lang="less" scoped>
.age .van-radio--horizontal {
    margin-bottom: 5px !important;
}

/deep/ .item1::before {
    position: absolute;
    left: -4px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
}

/deep/ .item2::before {
    position: absolute;
    left: 33%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
}

.gender-style {
    width: 95%;
    margin-left: 16px;
    margin-top: 10px;
    display: flex;
}

.address-style {
    margin-top: 10px;
}

.email-style {
    display: flex;
    width: 100%;
}

.gender-drop {
    margin-left: 16px;
}

.birth-style {
    width: 95%;
    margin-left: 16px;
    margin-top: 10px;
    align-items: center;
    display: flex;
}
</style>

<style>
.noticeWidth {
    width: 100%;
}
</style>
